import { useState } from 'react'
import { Button, Container, Form, InputGroup } from 'react-bootstrap';
import './CreatorMeme.css'
import html2canvas from 'html2canvas';

const CreateMeme = () => {

    const [image, setImage] = useState(null)
    const [linea1, setLinea1] = useState('')
    const [linea2, setLinea2] = useState('')

    const handleImageChange = (e) => {
        const file = e.target.files[0]
        const reader = new FileReader()
        reader.onloadend = () => {
            setImage(reader.result)
        }
        if(file){
            reader.readAsDataURL(file)
        }
    }

    const handleLinea1Change = (e) => {
        setLinea1(e.target.value)
    }

    const handleLinea2Change = (e) => {
        setLinea2(e.target.value)
    }

    const onClickButton = (e) => {
        html2canvas(document.querySelector("#capture")).then(canvas => {
            const nombreImagen = window.prompt('Ingresa el nombre de la imagen:', 'mi_imagen');
            if (nombreImagen) {
                // Obtener la URL de datos de la imagen desde el canvas
                const imagenURL = canvas.toDataURL();
            
                // Crear un enlace temporal para la descarga
                const enlaceDescarga = document.createElement('a');
                enlaceDescarga.href = imagenURL;
                enlaceDescarga.download = `${nombreImagen}.png`; // Agregar el nombre personalizado y la extensión
            
                // Hacer clic en el enlace para iniciar la descarga
                enlaceDescarga.click();
            }
        });
    }

    return (
        <>
            
            <Container className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '80vh' }}>
                <h1>CREATOR MEME</h1>
                <Form>
                    <Form.Group controlId='imageUpload'>
                        <Form.Control type="file" accept='image/*' onChange={handleImageChange}/>
                    </Form.Group>
                </Form>
                {image && (
                    <>
                        <InputGroup className="mb-3 mt-2">
                            <InputGroup.Text id="basic-addon1">Arriba</InputGroup.Text>
                            <Form.Control
                            placeholder="Linea 1"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={handleLinea1Change}
                            />
                        </InputGroup>
                        <InputGroup className="mb-3 ">
                            <InputGroup.Text id="basic-addon1">Abajo</InputGroup.Text>
                            <Form.Control
                            placeholder="Linea 1"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={handleLinea2Change}
                            />
                        </InputGroup>
                        <h5>Vista previa:</h5>
                        <div id="capture" className='mt-4 meme'>
                            <span>{linea1}</span>
                            <span>{linea2}</span>
                            <img 
                                src={image} 
                                alt="Preview" 
                            />
                        </div>
                        <Button 
                            className="mt-2" 
                            variant="success"
                            onClick={onClickButton}>
                                Guardar MEME
                        </Button>
                    </>

                )}
            </Container>
        </>
    )
}

export default CreateMeme